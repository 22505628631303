<template><div id="bg">
  <img src="/assets/bars.svg" id="loader" ref="loader" />
  <v-stage
    :config="configKonva" ref="stage" v-on:keyup.esc="tmp">
    <v-layer ref="layer">
      <!-- dynamic -->
    </v-layer>
  </v-stage>
</div></template>

<script>
import Konva from 'konva';

export default {
  name: 'Main',
  data: function(){
    return {
      ws: null,
      configKonva: {
        width: window.innerWidth,
        height: window.innerHeight,

      },
      running: false,
    };
  },
  created: function(){
    document.onkeydown = this.onkeydown;
  },
  mounted: function(){

    const stage = this.$refs.stage.getNode();
    const layer = this.$refs.layer.getNode();
    const loader = this.$refs.loader;

    const MAX_RAD = stage.width()/4;
    const MAX_FONT = 48;
    const MAX_FADE_SPEED = 0.75;
    const MIN_FADE_SPEED = 0.15;

    const animation = new Konva.Animation(function(frame){

      const timeMult = (frame.timeDiff / 1000);
      for (const node of layer.getChildren()){
        var fadeSec = -1;
        if (node.getClassName() === 'Circle'){
          fadeSec = Math.min(MAX_FADE_SPEED, node.radius()/MAX_RAD);
        }else{ // text
          fadeSec = Math.min(MAX_FADE_SPEED, +(node.text()))
        }

        const fadeOut = (1-Math.max(MIN_FADE_SPEED, fadeSec)) * timeMult;

        const newOp = node.opacity() - fadeOut;
        if (newOp <= 0){
          node.destroy();
        }else{
          node.opacity(newOp);
        }
      }

    }, layer);

    // setup WS

    this.ws = new WebSocket('wss://stream.binance.com:9443/ws/btcusdt@trade');

    this.ws.onmessage = function(ev){
      if (!this.running){
        this.running = true;

        // get and destroy loader
        loader.remove();

        animation.start();
      }

      const data = JSON.parse(ev.data);
      //const price = +data['p'];
      const quantity = +data['q'];
      const maker = data['m'];

      const circle = new Konva.Circle({
        x: Math.round(Math.random() * stage.width()),
        y: Math.round(Math.random() * stage.height()),
        radius: Math.min(MAX_RAD, Math.max(5, Math.round(quantity*512))),
        fill: maker ? 'green' : 'red',
      });
      layer.add(circle);

      const label = new Konva.Text({
        x: circle.x(),
        y: circle.y(),
        text: quantity+'',
        fontSize: Math.min(MAX_FONT, Math.max(14, Math.round(quantity*100))),
        fontFamily: 'monospace',
        fill: 'lightgrey',
        fontStyle: quantity > 0.2 ? 'bold' : 'normal',
      });
      label.offsetX(label.width()/2); // centre
      if (circle.radius() > 5) // otherwise leave text off-centre for readability
        label.offsetY(label.height()/2);

      layer.add(label);

      //layer.draw();
      // the animator will draw the layer's nodes

    }

  },
  methods: {
    closeWS: function(){
      this.ws.close();
    },
    onkeydown: function(event){
      if (event.which === 27){ // ESC
        this.closeWS();
      }
    },
  },
  unmounted: function(){
    this.closeWS();
  },
}

</script>

<style scoped>
#bg {
  background-color: #102331;
}
#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
